import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const HeaderPinnedStateContext = createContext();
const HeaderPinnedUpdaterContext = createContext();

function HeaderPinnedProvider({ children }) {
  const [headerPinned, setHeaderPinned] = useState(false);
  return (
    <HeaderPinnedStateContext.Provider value={headerPinned}>
      <HeaderPinnedUpdaterContext.Provider value={setHeaderPinned}>
        {children}
      </HeaderPinnedUpdaterContext.Provider>
    </HeaderPinnedStateContext.Provider>
  );
}

HeaderPinnedProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function useHeaderPinnedState() {
  const headerPinnedState = useContext(HeaderPinnedStateContext);
  if (typeof headerPinnedState === 'undefined') {
    throw new Error(
      'useHeaderPinnedState must be used within a HeaderPinnedProvider'
    );
  }
  return headerPinnedState;
}

function useHeaderPinnedUpdater() {
  const setHeaderPinned = useContext(HeaderPinnedUpdaterContext);
  if (typeof setHeaderPinned === 'undefined') {
    throw new Error(
      'useHeaderPinnedUpdater must be used within a HeaderPinnedProvider'
    );
  }
  return setHeaderPinned;
}

function useHeaderPinned() {
  const headerPinned = useHeaderPinnedState();
  const setHeaderPinned = useHeaderPinnedUpdater();

  return { headerPinned, setHeaderPinned };
}

export {
  HeaderPinnedProvider,
  useHeaderPinnedState,
  useHeaderPinnedUpdater,
  useHeaderPinned,
};
