import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import getIn from 'lodash/get';

const Paragraph = styled.p`
  margin: 1.375rem 0;
  font-size: 0.875rem;
  color: ${props => getIn(props, 'theme.paragraph.textColor', '#1c2f45')};
  letter-spacing: 0;
  line-height: 1.375rem;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`;

export default Paragraph;
