import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Icons from 'assets/content-icons';

const Empty = styled.span``;

function PolygonIcon({ type, ...props }) {
  if (Icons[type]) {
    const IconComponent = Icons[type];
    return <IconComponent {...props} />;
  }

  return <Empty>{type}</Empty>;
}

PolygonIcon.propTypes = {
  type: PropTypes.oneOf(Object.keys(Icons)).isRequired,
};

export default PolygonIcon;
