import MobileDetect from 'mobile-detect';

export function isBrowser() {
  return typeof window !== 'undefined' && typeof document !== 'undefined';
}

export function detectDefaultScreenClass() {
  const md = new MobileDetect(isBrowser() ? window.navigator.userAgent : '');

  let defaultScreenClass = 'xl';

  if (md.phone() !== null) defaultScreenClass = 'xs';
  if (md.tablet() !== null) defaultScreenClass = 'md';

  return defaultScreenClass;
}
