import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { IoIosArrowForward, IoIosArrowRoundForward } from 'react-icons/io';
import styled from 'styled-components';

const Link1 = styled.div`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  & + & {
    margin-top: 20px;
  }
`;

export const Text1 = styled.span`
  color: #fff;
  font-size: 26px;
  font-weight: bold;
  line-height: 34px;
  font-family: Helvetica, Arial, sans-serif;
`;

const Text2 = styled.span`
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
`;

const Level2Heading = styled.h3`
  opacity: 0.5;
  color: #ffffff;
  font-family: Helvetica;
  margin-top: 24px;
`;

const Link2 = styled(Link)`
  font-size: 16px;
  line-height: 21px;
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 21px;
`;

const Arrow = styled(IoIosArrowForward)`
  color: #fff;
  font-size: 24px;
`;
const ArrowRound = styled(IoIosArrowRoundForward)`
  color: #fff;
  font-size: 24px;
  margin-right: 15px;
`;

function MenuItem({
  onChange,
  menuKey,
  className,
  label,
  link,
  subMenu,
  level,
}) {
  if (level === 1) {
    const hasSub = Boolean(subMenu);
    const as = hasSub ? 'div' : Link;
    const onClick = hasSub
      ? () => {
          onChange(menuKey);
        }
      : null;
    return (
      <Link1
        className={className}
        as={as}
        to={link}
        title={label}
        onClick={onClick}
      >
        <Text1>{label}</Text1>
        {subMenu ? <Arrow /> : null}
      </Link1>
    );
  }

  if (link) {
    return (
      <Link2 to={link} className={className} title={label}>
        <ArrowRound />
        <Text2>{label}</Text2>
      </Link2>
    );
  }

  return <Level2Heading>{label}</Level2Heading>;
}

MenuItem.defaultProps = {
  className: null,
  level: 1,
  label: null,
  link: null,
  subMenu: null,
  onChange: null,
};

MenuItem.propTypes = {
  menuKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  level: PropTypes.oneOf([1, 2]),
  label: PropTypes.string,
  link: PropTypes.string,
  subMenu: PropTypes.string,
  onChange: PropTypes.func,
};

export default MenuItem;
