import { useState, useEffect, useCallback } from 'react';

const COOKIE_ACCEPTED_KEY = 'ts.cookie-accepted';
const ACCEPTED = 'accepted';

function useAccepted() {
  const [accepted, setAccepted] = useState(false);

  const accept = useCallback(() => {
    localStorage.setItem(COOKIE_ACCEPTED_KEY, ACCEPTED);
    setAccepted(true);
  }, []);

  useEffect(() => {
    const cookieAccetpted = localStorage.getItem(COOKIE_ACCEPTED_KEY);

    if (cookieAccetpted === ACCEPTED) {
      setAccepted(true);
    }
  }, []);

  return { accepted, accept };
}

export default useAccepted;
