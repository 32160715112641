import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContactInfo from 'components/ContactInfo';
import Item from './Item';

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledContactInfo = styled(ContactInfo)`
  margin-left: 2.5rem;
`;

function DesktopNav({ className, items }) {
  const renderItem = item => (
    <Item {...item} key={item.menuKey}>
      {item.label}
    </Item>
  );

  return (
    <Wrapper className={className}>
      {items.map(renderItem)}
      <StyledContactInfo showAddress={false} />
    </Wrapper>
  );
}

const MenuItemProps = PropTypes.shape({
  label: PropTypes.string,
  link: PropTypes.string,
});

DesktopNav.defaultProps = {
  className: null,
  items: [],
};

DesktopNav.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(MenuItemProps),
};

export default DesktopNav;
