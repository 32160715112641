import { setConfiguration } from 'react-grid-system';

export function configureGridSystem({ ...config }) {
  setConfiguration({
    containerWidths: [540, 750, 960, 1319],
    gridColumns: 12,
    gutterWidth: 33,
    ...config,
  });
}
