import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.button`
  border-radius: 3px;
  padding: 1.125rem 1.25rem 1.3125rem 1.25rem;
  background-color: #0276f1;
  font-size: 1rem;
  line-height: 1.3125rem;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;

  &:hover {
    background-color: #015ec0;
  }

  &[disabled] {
    opacity: 0.4;
    cursor: default;
  }
`;

function Button({ children, ...props }) {
  return <Container {...props}>{children}</Container>;
}

Button.defaultProps = {};

Button.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Button;
