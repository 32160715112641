import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, ThemeContext } from 'styled-components';
import { ScreenClassProvider } from 'react-grid-system';
import { configureGridSystem } from 'utils/grid';
import breakpoints from 'utils/breakpoints';
import { detectDefaultScreenClass } from 'utils/browser';
import { HeaderPinnedProvider } from 'components/Header/headerPinnedContext';
import Header, { HEADER_HEIGHT } from 'components/Header';
import Footer, { FOOTER_HEIGHT, FOOTER_HEIGHT_MD } from 'components/Footer';
import SEO from 'components/SEO';
import CookiePolicy from 'components/CookiePolicy';
import { dark } from 'themes';

configureGridSystem({ defaultScreenClass: detectDefaultScreenClass() });

const Wrapper = styled.div`
  @media screen and (min-width: ${breakpoints.lg}) {
    padding-bottom: ${`${FOOTER_HEIGHT}px`};
  }

  @media screen and (min-width: ${breakpoints.md}) {
    padding-bottom: ${`${FOOTER_HEIGHT_MD}px`};
  }
`;

const Body = styled.main`
  padding-top: ${({ fullSize }) => (fullSize ? 0 : `${HEADER_HEIGHT}px`)};
  width: 100%;
  overflow-x: ${({ overflow }) => (overflow ? 'unset' : 'hidden')};
  position: relative;
  z-index: 2;
  background: #fff;
`;

function Layout({
  className,
  fullSize,
  overflow,
  children,
  pageTitle,
  headerTheme,
}) {
  const parentTheme = useContext(ThemeContext) || dark;

  return (
    <Wrapper className={className}>
      <ScreenClassProvider>
        <HeaderPinnedProvider>
          <SEO title={pageTitle} />
          <ThemeProvider theme={headerTheme || parentTheme}>
            <Header />
          </ThemeProvider>
          <Body fullSize={fullSize} overflow={overflow}>
            {children}
          </Body>
          <Footer />
          <CookiePolicy />
        </HeaderPinnedProvider>
      </ScreenClassProvider>
    </Wrapper>
  );
}

Layout.defaultProps = {
  className: null,
  headerTheme: null,
  fullSize: false,
  overflow: false,
};

Layout.propTypes = {
  className: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
  fullSize: PropTypes.bool,
  overflow: PropTypes.bool,
  headerTheme: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

export default Layout;
