import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { withTheme } from 'styled-components';
import { ReactComponent as Logo } from 'assets/ts-logo.svg';
import { ReactComponent as LogoLight } from 'assets/ts-logo-light.svg';
import { ReactComponent as LogoWhite } from 'assets/ts-logo-white.svg';

const Container = styled.div`
  width: 108px;
  height: 25px;
`;

const byTheme = {
  dark: Logo,
  light: LogoLight,
  white: LogoWhite,
};

function TrustingSocialLogo({ className, theme }) {
  const LogoByTheme = byTheme[theme.logoVersion];

  return (
    <Link to="/">
      <Container as={LogoByTheme} className={className} />
    </Link>
  );
}

TrustingSocialLogo.defaultProps = {
  className: null,
  theme: {
    logoVersion: 'dark',
  },
};

TrustingSocialLogo.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.shape({
    logoVersion: PropTypes.oneOf(Object.keys(byTheme)),
  }),
};

export default withTheme(memo(TrustingSocialLogo));
