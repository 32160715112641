export const light = {
  header: {
    iconColor: '#1c2f45',
    logo: 'dark',
    navTextColor: '#242424',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
  },
  logoVersion: 'dark',
  contactInfo: {
    socialIcons: {
      borderColorHover: 'rgba(36, 36, 36, 0.5)',
      borderColor: 'rgba(36, 36, 36, 0.2)',
      iconColor: '#1c2f45',
    },
    textColor: '#1c2f45',
  },
  intro: {
    bodyColor: 'rgba(28,47,69,1)',
  },
  heading: {
    textColor: '#ffffff',
  },
  paragraph: {
    textColor: '#ffffff',
  },
  link: {
    textColor: '#0276f1',
  },
};

export const dark = {
  header: {
    iconColor: '#fff',
    logo: 'light',
    navTextColor: '#fff',
    backgroundColor: 'transparent',
  },
  logoVersion: 'light',
  contactInfo: {
    socialIcons: {
      iconColor: '#fff',
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
    textColor: 'rgba(255, 255, 255, 0.5)',
  },
  intro: {
    bodyColor: 'rgba(28,47,69,1)',
  },
  heading: {
    textColor: '#1c2f45',
  },
  paragraph: {
    textColor: '#1c2f45',
  },
  link: {
    textColor: '#0276f1',
  },
};

export const white = {
  header: {
    iconColor: '#ffffff',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  logoVersion: 'white',
  contactInfo: {
    socialIcons: {
      iconColor: '#ffffff',
      borderColor: 'rgba(255, 255, 255, 0.3)',
      borderColorHover: 'rgba(255, 255, 255, 0.6)',
    },
    textColor: '#ffffff',
  },
  intro: {
    bodyColor: '#ffffff',
  },
  heading: {
    textColor: '#ffffff',
  },
  paragraph: {
    textColor: '#ffffff',
  },
  link: {
    textColor: '#ffffff',
  },
};
