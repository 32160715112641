import { useCallback } from 'react';

export function blockScroll() {
  document.body.style.overflow = 'hidden';
}

export function unBlockScroll() {
  document.body.style.overflow = 'auto';
}

export function isDesktopAndTablet({ screenClass }) {
  return ['md', 'lg', 'xl'].includes(screenClass);
}

export function isDesktop({ screenClass }) {
  return ['lg', 'xl'].includes(screenClass);
}

export function isTablet({ screenClass }) {
  return screenClass === 'md';
}

export function scrollTo(selector, parent = document, delta = 0) {
  let top;
  if (typeof selector === 'number') {
    top = selector;
  } else {
    const element = parent.querySelector(selector);
    if (!element) {
      console.error(`#${selector} is not existing`);
      return;
    }
    top = element.getBoundingClientRect().top + window.pageYOffset - delta;
  }

  window.scrollTo({
    top,
    behavior: 'smooth',
  });
}

export function useScrollTo(target) {
  return useCallback(() => {
    scrollTo(typeof target === 'number' ? target : `#${target}`);
  }, [target]);
}

export function useScrollTop() {
  return useScrollTo(0);
}
