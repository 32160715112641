import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import getIn from 'lodash/get';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import breakpoints from 'utils/breakpoints';

const Container = styled.div``;

const AddressLabel = styled.div`
  color: ${props => getIn(props, 'theme.contactInfo.textColor', '#ffffff')};
  font-size: 14px;
  line-height: 28px;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    text-align: left;
  }
`;

const Address = styled.p`
  font-size: 14px;
  line-height: 28px;
  margin: 0 0 30px 0;
  text-align: center;
  color: ${props => getIn(props, 'theme.contactInfo.textColor', '#ffffff')};

  @media (min-width: ${breakpoints.md}) {
    text-align: left;
  }
`;

const SocialList = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoints.md}) {
    justify-content: flex-start;
  }
`;

const IconWrap = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  transition: border-color 200ms linear;
  border: 1px solid
    ${props =>
      getIn(props, 'theme.contactInfo.socialIcons.borderColor', '#e2e2e2')};

  & + & {
    margin-left: 8.5px;
  }

  &:hover {
    border-color: ${props =>
      getIn(
        props,
        'theme.contactInfo.socialIcons.borderColorHover',
        '#e2e2e2'
      )};
  }
`;

const Icon = styled(FaFacebookF)`
  color: ${props =>
    getIn(props, 'theme.contactInfo.socialIcons.iconColor', '#1c2f45')};
`;

function ContactInfo({ className, showAddress }) {
  return (
    <Container className={className}>
      {showAddress && <AddressLabel>Kantor Cabang Indonesia:</AddressLabel>}
      {showAddress && <Address>
        Treasury Tower, 51st Floor District 8, Lot 28
        Jl. Jend. Sudirman Kav. 52-53,
        Jakarta 12190</Address>}
      <SocialList>
        <IconWrap
          target="_blank"
          href="https://www.facebook.com/trustingsocial.company"
        >
          <Icon as={FaFacebookF} />
        </IconWrap>
        <IconWrap
          target="_blank"
          href="https://www.linkedin.com/company/trustingsocial"
        >
          <Icon as={FaLinkedinIn} />
        </IconWrap>
      </SocialList>
    </Container>
  );
}

ContactInfo.defaultProps = {
  className: null,
  showAddress: true,
};

ContactInfo.propTypes = {
  className: PropTypes.string,
  showAddress: PropTypes.bool,
};

export default memo(ContactInfo);
