import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Item from './SubMenuItem';

const Container = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  display: flex;
  align-items: stretch;
  position: relative;

  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 15px;
    margin-left: -15px;
  }

  &:before {
    border-color: rgba(227, 227, 227, 0);
    border-bottom-color: #e3e3e3;
    border-width: 16px;
    margin-left: -16px;
  }
`;

function DesktopSubMenu({ className, items }) {
  return (
    <Container className={className}>
      {items.map(item => (
        <Item {...item} key={item.menuKey} />
      ))}
    </Container>
  );
}

DesktopSubMenu.defaultProps = {
  className: null,
  items: [],
};

DesktopSubMenu.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(Item.propTypes)),
};

export default DesktopSubMenu;
