import React from 'react';
import PropTypes from 'prop-types';
import { Match } from '@reach/router';
import styled from 'styled-components';
import Link from 'components/Link/Pure';
import getIn from 'lodash/get';
import SubMenu from './SubMenu';

const Container = styled(Link)`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4375rem;
  color: ${props => getIn(props, 'theme.header.navTextColor', '#242424')};
  text-decoration: none;
  transition: 0.3s ease;

  & + &,
  span + & {
    margin-left: 2.5rem;
  }

  &:hover {
    color: #0276f1;
  }

  &.active {
    color: #0276f1;
  }
`;

const SubMenuWrap = styled.div`
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 25px;
  transition: visibility, opacity 200ms linear;
`;

const ContainerWithSubMenu = styled.span`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4375rem;
  color: ${props => getIn(props, 'theme.header.navTextColor', '#242424')};
  text-decoration: none;
  transition: 0.3s ease;
  position: relative;
  cursor: pointer;
  &:hover {
    color: #0276f1;
  }

  &.active {
    color: #0276f1;
  }

  ${Container} + & {
    margin-left: 2.5rem;
  }

  &:hover ${SubMenuWrap} {
    visibility: visible;
    opacity: 1;
  }
`;

function DesktopMenuItem({ link, label, subMenu, menuKey }) {
  if (subMenu) {
    return (
      <Match path={`/${menuKey}/:id`}>
        {props => (
          // eslint-disable-next-line react/prop-types
          <ContainerWithSubMenu className={props.match ? 'active' : null}>
            {label}
            <SubMenuWrap>
              <SubMenu items={subMenu} />
            </SubMenuWrap>
          </ContainerWithSubMenu>
        )}
      </Match>
    );
  }

  return (
    <Container
      to={link}
      title={label}
      activeClassName="active"
      partiallyActive={link !== '/'}
    >
      {label}
    </Container>
  );
}

DesktopMenuItem.defaultProps = {
  link: null,
  subMenu: null,
};

DesktopMenuItem.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string.isRequired,
  menuKey: PropTypes.string.isRequired,
  subMenu: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.string.isRequired,
      description: PropTypes.string,
      menuKey: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
};

export default DesktopMenuItem;
