import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, keyframes } from 'styled-components';
import { Container, Row, Col, Visible } from 'react-grid-system';
import breakpoints from 'utils/breakpoints';
import TSLogo from 'components/TSLogo';
import ContactInfo from 'components/ContactInfo';
import { dark } from 'themes';
import { TiArrowUp } from 'react-icons/ti';
import { useScrollTop } from 'utils';
import { ReactComponent as MapImage } from 'assets/map-2.svg';
import footerContent from 'contents/common/footer.json';
import chunk from 'lodash/chunk';
import FooterNav from './FooterNav';
import Regulator from './Regulator';

export const FOOTER_HEIGHT = 600;
export const FOOTER_HEIGHT_MD = 800;

const Wrapper = styled.footer`
  padding: 2.56rem 0 4.19rem;
  background: #161615;

  @media screen and (min-width: ${breakpoints.md}) {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 5rem 0;
    * {
      opacity: ${props => props.opacity};
    }
  }
`;

const StyledMapImage = styled(MapImage)`
  height: 11.38rem;
  width: auto;
  margin: 0 auto 2.31rem;
  display: block;

  @media screen and (min-width: ${breakpoints.md}) {
    width: 100%;
    max-width: 29.75rem;
    margin: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    align-items: flex-start;
  }
`;

const Logo = styled(TSLogo)`
  margin-bottom: 0.63rem;
`;

const EmailAddress = styled.a`
  font-size: 0.875rem;
  line-height: 1.75rem;
  margin-top: 2.75rem;
  text-decoration: underline;
  text-align: center;
  display: block;
  color: rgb(255, 255, 255, 0.5);

  &:hover {
    color: #ffffff;
  }

  @media (min-width: ${breakpoints.md}) {
    text-align: left;
  }
`;

const Text = styled.p`
  color: rgb(255, 255, 255, 0.5);
  font-size: 0.88rem;
  line-height: 1.75rem;
  text-decoration: none;
  margin: 0;
  & + & {
    margin-left: 0.94rem;
  }
`;

const CopyRight = styled(Text)`
  margin-top: 2.75rem;
  margin-bottom: 0.63rem;
  display: block;
  text-align: center;
  font-size: 0.88rem;

  @media screen and (min-width: ${breakpoints.md}) {
    margin: 0;
  }

  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
  }
`;

const FooterLinks = styled.div`
  text-decoration: none;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 4.81rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    text-align: left;
  }
`;

const upDownAnimation = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(4px);
  }
`;

const GoToTop = styled(Text)`
  background: none;
  padding: none;
  border: none;
  display: flex;
  align-items: center;
  outline: none;
  line-height: 1;

  &:hover {
    cursor: pointer;
    color: #ffffff;
    svg {
      animation: ${upDownAnimation} 500ms infinite;
    }
  }

  svg {
    margin-left: 3px;
    font-size: 24px;
  }
`;
const currentYear = new Date().getFullYear();

const coppyRightColOffset = { lg: 5 };
const goToTopColOffset = { lg: 2 };

function Footer({ className }) {
  const scrollTop = useScrollTop();
  return (
    <ThemeProvider theme={dark}>
      <Wrapper className={className}>
        <Container>
          <Row>
            <Col md={6} lg={5}>
              <StyledMapImage />
            </Col>
            <Col md={6} lg={3}>
              <Content>
                <Logo />
                <ContactInfo />
              </Content>
              <EmailAddress href="mailto:contact@trustingsocial.com">
                contact@trustingsocial.com
              </EmailAddress>
            </Col>
            <Col>
              <Row>
                {chunk(footerContent.menu, 3).map((menuItems, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Col
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    offset={{
                      xs: 1,
                      sm: i === 0 ? 2 : 0,
                      md: 0,
                      lg: 0,
                    }}
                    xs={4}
                    sm={5}
                    md={6}
                    lg={6}
                  >
                    <Visible xs sm md>
                      <div style={{ marginTop: '44px' }} />
                    </Visible>
                    <FooterNav
                      title={i === 0 ? 'Perusahaan' : null}
                      items={menuItems}
                    />
                  </Col>
                ))}
              </Row>
              <Regulator />
            </Col>
          </Row>
          <FooterLinks>
            <Row>
              <Col lg={3} offset={coppyRightColOffset}>
                <CopyRight>{`© 2013 - ${currentYear} Trust IQ Pte. Ltd.`}</CopyRight>
              </Col>
              <Visible lg xl>
                <Col lg={2} offset={goToTopColOffset}>
                  <GoToTop onClick={scrollTop} as="button">
                    <span>Kembali ke atas</span>
                    <TiArrowUp />
                  </GoToTop>
                </Col>
              </Visible>
            </Row>
          </FooterLinks>
        </Container>
      </Wrapper>
    </ThemeProvider>
  );
}

Footer.defaultProps = {
  className: null,
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default memo(Footer);
