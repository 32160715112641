import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'components/Link/Pure';
import ContentIcon from 'components/ContentIcon';

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  &:hover {
    background: #f4f9ff;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(244, 249, 255, 1) 18%,
      rgba(244, 249, 255, 1) 100%
    );
  }
  & + & {
    border-left: 1px solid #e3e3e3;
  }
`;

const Container = styled.div`
  padding: 54px 16px 60px;
  box-sizing: border-box;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.span`
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
  color: #242424;
  margin-bottom: 10px;
  margin-top: 17px;
  a:hover & {
    color: #0276f1;
  }
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  color: #1c2f45;
`;

function DesktopSubMenuItem({ className, iconType, label, description, link }) {
  return (
    <StyledLink to={link} title={label}>
      <Container className={className}>
        <ContentIcon type={iconType} />
        <Label>{label}</Label>
        <Description>{description}</Description>
      </Container>
    </StyledLink>
  );
}

DesktopSubMenuItem.defaultProps = {
  className: null,
  description: null,
  iconType: 'Laws',
};

DesktopSubMenuItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.string.isRequired,
  iconType: ContentIcon.propTypes.type,
};

export default DesktopSubMenuItem;
