import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

function useIsAbsoluteURL(url) {
  return useMemo(
    () =>
      url &&
      (url.indexOf('://') > 0 ||
        url.indexOf('//') === 0 ||
        url.indexOf('mailto') === 0),
    [url]
  );
}

function PureLink({ to, ...props }) {
  const isAbsoluteURL = useIsAbsoluteURL(to);

  if (isAbsoluteURL) {
    return <a href={to} {...props} />;
  }

  return <GatsbyLink to={to} {...props} />;
}

PureLink.defaultProps = {
  className: null,
};

PureLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default memo(PureLink);
