import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowBack } from 'react-icons/io';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import MenuItem, { Text1 } from './MenuItem';

const duration = 200;

const defaultStyle = {
  transition: `opacity, transform ${duration}ms ease`,
};

const menuDefault = {
  ...defaultStyle,
  opacity: 1,
  transform: 'translateX(0)',
};

const subMenuDefaultStyle = {
  ...defaultStyle,
  opacity: 0,
  transform: 'translateX(50%)',
};

const subMenuTransitionStyles = {
  entering: { opacity: 1, transform: 'translateX(0)' },
  entered: { opacity: 1, transform: 'translateX(0)' },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const menuTransitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0, transform: 'translateX(-50%)' },
  exited: { opacity: 0, transform: 'translateX(-50%)' },
};

const Container = styled.div``;
const Back = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BackIcon = styled(IoIosArrowBack)`
  color: #fff;
  font-size: 24px;
  margin-right: 15px;
`;
const CurrentMenuList = styled.nav``;
const SubMenu = styled.div``;

function Menu({ className, items, level }) {
  const [selected, onChange] = useState(null);
  const clearParent = () => onChange(null);
  const renderItem = item => (
    <MenuItem onChange={onChange} {...item} level={level} key={item.menuKey} />
  );
  const currentItem = items.find(item => item.menuKey === selected);
  return (
    <Container className={className}>
      <Transition in={!selected} timeout={0}>
        {state => (
          <CurrentMenuList
            style={{
              ...menuDefault,
              ...menuTransitionStyles[state],
            }}
          >
            {!selected && items.map(renderItem)}
          </CurrentMenuList>
        )}
      </Transition>
      <Transition in={Boolean(selected)} timeout={0}>
        {state => (
          <SubMenu
            style={{
              ...subMenuDefaultStyle,
              ...subMenuTransitionStyles[state],
            }}
          >
            {selected && (
              <>
                <Back onClick={clearParent}>
                  <BackIcon />
                  <Text1>{currentItem.label}</Text1>
                </Back>
                <Menu items={currentItem.subMenu} level={level + 1} />
              </>
            )}
          </SubMenu>
        )}
      </Transition>
    </Container>
  );
}

Menu.defaultProps = {
  className: null,
  level: 1,
  items: [],
};

const MenuItemProps = PropTypes.shape({
  label: PropTypes.string,
  link: PropTypes.string,
  subMenu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
    })
  ),
});

Menu.propTypes = {
  level: PropTypes.number,
  className: PropTypes.string,
  items: PropTypes.arrayOf(MenuItemProps),
};

export default Menu;
