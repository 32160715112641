import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import getIn from 'lodash/get';
import breakpoints from 'utils/breakpoints';

const Name = styled.p`
  margin: 0;
  opacity: 0.5;
  font-weight: 600;
  font-size: 1rem;
  text-align: ${props => (props.centered ? 'center' : 'inherit')};
  color: ${props => getIn(props, 'theme.heading.textColor', '#1c2f45')};
  letter-spacing: 0;
`;

const Title = styled.h3`
  margin: 1.88rem 0 0.75rem 0;
  font-family: Helvetica;
  font-size: 1.625rem;
  font-weight: 700;
  letter-spacing: 0;
  color: ${props => getIn(props, 'theme.heading.textColor', '#1c2f45')};
  text-align: ${props => (props.centered ? 'center' : 'inherit')};
  @media (min-width: ${breakpoints.md}) {
    margin: 2.5rem 0 2.5rem 0;
    font-size: 2.625rem;
  }
`;

function Heading({
  name,
  title,
  className,
  nameClass,
  titleClass,
  centered,
  ...props
}) {
  return (
    <>
      {name && (
        <Name centered={centered} className={classNames(className, nameClass)}>
          {name}
        </Name>
      )}
      {title && (
        <Title
          {...props}
          centered={centered}
          className={classNames(className, titleClass)}
        >
          {title}
        </Title>
      )}
    </>
  );
}

Heading.propTypes = {
  name: PropTypes.node,
  title: PropTypes.node,
  className: PropTypes.string,
  nameClass: PropTypes.string,
  titleClass: PropTypes.string,
  centered: PropTypes.bool,
};

Heading.defaultProps = {
  name: '',
  title: '',
  className: '',
  nameClass: '',
  titleClass: '',
  centered: false,
};

Heading.Name = Name;
Heading.Title = Title;

export default Heading;
