import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import getIn from 'lodash/get';

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 23px;
  height: 15px;
`;

const barStyles = css`
  position: absolute;
  width: 23px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: ${props => getIn(props, 'theme.header.iconColor', '#000')};
`;

const middleBarActive = css`
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(225deg);

  &::after {
    bottom: 0;
    transition: bottom 0.1s ease-out,
      transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
    transform: rotate(-90deg);
  }

  ::before {
    top: 0;
    transition: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
    opacity: 0;
  }
`;

const Inner = styled.div`
  ${barStyles}
  transition-timing-function: cubic-bezier(.55,.055,.675,.19);
  transition-duration: 0.22s;
  top: 50%;
  display: block;
  margin-top: -2px;

  &::after,
  &::before {
    ${barStyles}
    content: '';
    display: block;
  }

  &::before {
    top: -7px;
    transition: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
  }

  &::after {
    bottom: -7px;
    transition: bottom 0.1s ease-in 0.25s,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  ${props => (props.active ? middleBarActive : null)}
`;

const Hamburger = ({ active, ...props }) => {
  return (
    <Wrapper {...props}>
      <Inner active={active} />
    </Wrapper>
  );
};

Hamburger.defaultProps = {
  active: false,
};

Hamburger.propTypes = {
  active: PropTypes.bool,
};

export default Hamburger;
