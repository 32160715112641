import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

function Img({ childImageSharp, extension, publicURL, alt, ...restProps }) {
  if (publicURL) {
    return (
      <LazyLoadImage
        effect="opacity"
        {...restProps}
        src={publicURL}
        alt={alt}
      />
    );
  }
  if (childImageSharp) {
    return <GatsbyImage fluid={childImageSharp.fluid} alt={alt} />;
  }
  return null;
}

Img.defaultProps = {
  childImageSharp: null,
  extension: '',
  publicURL: '',
  alt: '',
};

Img.propTypes = {
  childImageSharp: PropTypes.shape({
    fluid: PropTypes.shape({}),
  }),
  extension: PropTypes.string,
  publicURL: PropTypes.string,
  alt: PropTypes.string,
};

export default Img;
