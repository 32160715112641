import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Container, Hidden, Visible } from 'react-grid-system';
import styled, {
  withTheme,
  ThemeProvider,
  ThemeContext,
  css,
} from 'styled-components';
import HeadRoom from 'react-headroom';
import getIn from 'lodash/get';
import { blockScroll, unBlockScroll } from 'utils';
import { light, dark, white } from 'themes';
import TSLogo from 'components/TSLogo';
import MobileNav from 'components/Nav/Mobile';
import DesktopNav from 'components/Nav/Desktop';
import { useHeaderPinned } from 'components/Header/headerPinnedContext';
import Hamburger from 'components/Nav/Mobile/Hamburger';
import headerContent from 'contents/common/header.json';

export const HEADER_HEIGHT = 80;

const Sticky = styled(HeadRoom)`
  position: fixed;
  width: 100%;
  z-index: 100;

  .headroom {
    transition: all 0.2s ease;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: transparent;
  }
  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }
  .headroom--scrolled {
    transition: transform 200ms ease-in-out;
  }
  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }
  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
    background: ${props =>
      getIn(props, 'theme.header.backgroundColor', 'transparent')};
    box-shadow: ${props =>
      getIn(props, 'theme.header.boxShadow', 'transparent')};
  }
`;

const Wrapper = styled(Container)``;

const Content = styled.div`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SiteLogo = styled(TSLogo)`
  position: relative;
  z-index: 2;
`;

const menuTogglerActiveStyle = css``;

const StyledHamburger = styled(Hamburger)`
  ${props => (props.active ? menuTogglerActiveStyle : null)};
`;

function Header({ className }) {
  const { headerPinned, setHeaderPinned } = useHeaderPinned();
  const [open, setOpen] = useState(false);
  const handleCloseMenu = () => setOpen(false);
  const handleOpenMenu = () => setOpen(true);
  const handlePinned = () => setHeaderPinned(true);
  const handleUnPin = () => setHeaderPinned(false);
  const parentTheme = useContext(ThemeContext) || dark;
  useEffect(() => {
    if (open) {
      blockScroll();
    } else {
      unBlockScroll();
    }
  }, [open]);

  let headerTheme = parentTheme;

  if (open) {
    headerTheme = white;
  } else if (headerPinned) {
    headerTheme = light;
  }
  return (
    <ThemeProvider theme={headerTheme}>
      <Sticky
        disableInlineStyles
        pinStart={0}
        onPin={handlePinned}
        onUnpin={handleUnPin}
        onUnfix={handleUnPin}
      >
        <Wrapper className={className} component="header">
          <Content>
            <SiteLogo />
            <Hidden lg xl>
              <StyledHamburger
                active={open}
                onClick={open ? handleCloseMenu : handleOpenMenu}
              />
            </Hidden>
            <Visible lg xl>
              <DesktopNav items={headerContent.menu} />
            </Visible>
          </Content>
        </Wrapper>
      </Sticky>
      <Hidden lg xl>
        <MobileNav
          items={headerContent.menu}
          open={open}
          onClose={handleCloseMenu}
        />
      </Hidden>
    </ThemeProvider>
  );
}

Header.defaultProps = {
  className: null,
  theme: {
    header: {
      logo: 'light',
    },
  },
};

Header.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.shape({
    header: PropTypes.shape({
      logo: PropTypes.oneOf(['light', 'dark']),
    }),
  }),
};

export default withTheme(Header);
