import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Link from 'components/Link/Pure';

const Container = styled.div`
  ${({ title, keepAligned }) =>
    !title &&
    keepAligned &&
    css`
      padding-top: 2.3125rem;
    `}
`;

const Title = styled.h3`
  color: rgb(255, 255, 255, 0.8);
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.0625rem;
  margin: 0 0 1.25rem;
  text-transform: uppercase;
`;

const Content = styled.nav``;

const Item = styled(Link)`
  color: rgb(255, 255, 255, 0.5);
  font-size: 0.875rem;
  line-height: 1.75rem;
  display: block;
  text-decoration: none;
  transition: color 0.3s linear;

  & + & {
    margin-top: 0.3125rem;
  }

  &:hover {
    color: #ffffff;
  }
`;

function FooterNav({ className, title, items, keepAligned }) {
  const renderItem = item => (
    <Item as={Link} key={item.label} title={item.label} to={item.link}>
      {item.label}
    </Item>
  );

  return (
    <Container className={className} title={title} keepAligned={keepAligned}>
      {title && <Title>{title}</Title>}
      <Content>{items.map(renderItem)}</Content>
    </Container>
  );
}

FooterNav.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  keepAligned: PropTypes.bool,
};

FooterNav.defaultProps = {
  className: null,
  title: null,
  items: [],
  keepAligned: true,
};

export default memo(FooterNav);
