import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { Transition } from 'react-transition-group';
import { white } from 'themes';
import { Container as GridContainer } from 'react-grid-system';
import ContactInfo from 'components/ContactInfo';
import earthBackground from 'assets/home/home-earth.svg';
import Menu from './Menu';

const duration = 200;

const defaultStyle = {
  transition: `all ${duration}ms ease`,
  opacity: 0,
  visibility: 'hidden',
};

const transitionStyles = {
  entering: { opacity: 1, transform: 'translateX(0)', visibility: 'visible' },
  entered: { opacity: 1, transform: 'translateX(0)', visibility: 'visible' },
  exiting: {
    opacity: 0.4,
    transform: 'translateX(-100%)',
    visibility: 'hidden',
  },
  exited: {
    opacity: 0.4,
    transform: 'translateX(-100%)',
    visibility: 'hidden',
  },
};

const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0276f1 url(${earthBackground}) center 90% no-repeat;
  background-size: 90%;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  overflow: auto;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  color: #fff;
`;

const HeaderWrap = styled(GridContainer)`
  border-bottom: 1px solid rgba(256, 256, 256, 0.2);
  width: 100%;
`;

const Header = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BodyWrap = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: stretch;
`;

const Body = styled.div`
  padding-top: 33px;
  padding-bottom: 33px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

function Nav({ className, open, items }) {
  return (
    <Transition in={open} timeout={duration}>
      {state => (
        <Overlay style={{ ...defaultStyle, ...transitionStyles[state] }}>
          <Container className={className}>
            <ThemeProvider theme={white}>
              <HeaderWrap>
                <Header>{/* <TSLogo /> */}</Header>
              </HeaderWrap>
              <BodyWrap>
                <Body>
                  <div>
                    <GridContainer>
                      <Menu items={items} />
                    </GridContainer>
                  </div>
                  <div>
                    <GridContainer>
                      <ContactInfo />
                    </GridContainer>
                  </div>
                </Body>
              </BodyWrap>
            </ThemeProvider>
          </Container>
        </Overlay>
      )}
    </Transition>
  );
}

Nav.defaultProps = {
  className: null,
  open: false,
  items: [],
};

Nav.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  items: Menu.propTypes.items,
};

export default Nav;
