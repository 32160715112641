import React, { memo } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
// import { Row } from 'react-grid-system';
import breakpoints from 'utils/breakpoints';

const Title = styled.h3`
  color: rgb(255, 255, 255, 0.8);
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.0625rem;
  margin: 0 0 1.25rem;
  text-transform: uppercase;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    text-align: left;
    margin-top: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    align-items: flex-start;
  }
`;

const StyledRow = styled(Row)`
  margin-top: 6.8rem;
`;

function Regulator() {
  return (
    <StyledRow>
      <Col>
        <Title>Terdaftar di OJK</Title>
        <Content>
          {/* <img
            src="/uploads/regulator-ojk-white.png"
            alt="Otoritas Jasa Keuangan"
          /> */}
        </Content>
      </Col>
    </StyledRow>
  );
}
// function Regulator() {
//   return null;
// }

Regulator.defaultProps = {
  className: null,
};

export default memo(Regulator);
